
import './App.css';
import { Suspense, lazy, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { Routes, Route, useLocation } from 'react-router-dom'
import Home from './views/home'
import Header from './common/header/index.jsx'
import Bottom from './common/bottom'
import Login from '@/components/Login'
import { getUserinfo, getMyFollows, getMyFans, userHeartbeat, getNotificationList } from '@/api'
import { setUser, setMyFollows, setMyFans, setNotifications } from '@/redux/modules/user/action'
import mqtt from 'mqtt'
import envCfg from '@/config/env'

const AboutUs = lazy(() => import('@/views/aboutUs'))
const Detail = lazy(() => import('@/views/detail'))
const Notification = lazy(() => import('@/views/notification'))
const UploadModel = lazy(() => import('@/views/UploadPage/Model/index.jsx'))
const UploadMaterial = lazy(() => import('@/views/UploadPage/Material/index.jsx'))
const ModelListByTag = lazy(() => import('@/views/list/tags/index.jsx'))
const ModelListByCategory = lazy(() => import('@/views/list/category/index.jsx'))
const ModelListBySubCategory = lazy(() => import('@/views/list/sub-category/index.jsx'))
const UserModel = lazy(() => import('@/views/user/model'))
const UserCollect = lazy(() => import('@/views/user/collect'))
const UserHistory = lazy(() => import('@/views/user/history'))
const UserOverview = lazy(() => import('@/views/user/overview'))
const UserSettingProfile = lazy(() => import('@/views/user/setting/profile'))
const UserSettingAccount = lazy(() => import('@/views/user/setting/account'))
const Search = lazy(() => import('@/views/search'))

let heartbeatInterval = null

const App = () => {

  const location = useLocation()
  const showLogin = useSelector((state) => state.user.showLogin)
  const notifications = useSelector((state) => state.user.notifications)
  const token = useSelector((state) => state.user.token)
  const user = useSelector((state) => state.user.userInfo)
  const [client, setClient] = useState(null)
  const dispatch = useDispatch()

  const fetchUserInfos = useCallback(async () => {
    try {
      const info = await getUserinfo()
      if (info.code === 0) {
        dispatch(setUser(info.data))
      }
    } catch (e) {
      console.log(e)
    }
    try {
      const followsInfo = await getMyFollows()
      if (followsInfo.code === 0) {
        dispatch(setMyFollows(followsInfo.data || []))
      }
    } catch (e) {
      console.log(e)
    }
    try {
      const fansInfo = await getMyFans()
      if (fansInfo.code === 0) {
        dispatch(setMyFans(fansInfo.data || []))
      }
    } catch (e) {
      console.log(e)
    }
  }, [dispatch])

  const handleHeartbeat = () => {
    if (heartbeatInterval) {
      clearInterval(heartbeatInterval)
    }
    heartbeatInterval = setInterval(() => {
      userHeartbeat()
    }, 30000)
  }

  const fetchNotificationList = async () => {
    try {
      const res = await getNotificationList()
      if (res.code === 0) {
        const msgMap = new Map()
        res.data.forEach(item => {
          msgMap.set(item.id, item)
        })
        dispatch(setNotifications(Array.from(msgMap.values())))
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (!token) {
      clearInterval(heartbeatInterval)
      heartbeatInterval = null
    }
  }, [token])

  useEffect(() => {
    if (user && user.id) {
      fetchNotificationList()
      handleHeartbeat()
    }
  }, [user])

  useEffect(() => {
    const mqttClient = mqtt.connect(`wss://${envCfg[process.env.REACT_APP_ENV]['API_MQTT_HOST']}/mqtt`, {
      username: 'metahub',  // 替换为实际的用户名
      password: 'metahub@qazx',  // 替换为实际的密码
      reconnectPeriod: 1000,
      connectTimeout: 30 * 1000,
    })
    mqttClient.on('error', (err) => {
      console.error('Connection error: ', err);
    })
    setClient(mqttClient)
  }, [])

  useEffect(() => {
    if (client && user && user.id) {
      client.on('connect', () => {
        client.subscribe(`msg/unread/user_${user.id}`)
        client.subscribe(`msg/read/user_${user.id}`)
      })
      client.on('message', (topic, message) => {
        if (topic.includes('read/user_')) {
          const msgMap = new Map()
          notifications.forEach(item => {
            msgMap.set(item.id, item)
          })
          const lastMsg = JSON.parse(message.toString())
          msgMap.set(lastMsg.id, lastMsg)
          dispatch(setNotifications(Array.from(msgMap.values())))
          fetchNotificationList()
        }
      })
    }
  }, [client, user])

  useEffect(() => {
    token && fetchUserInfos()
  }, [token, fetchUserInfos])

  return (
    <>
      <Header />
      <div className='router-view' style={{ background: location.pathname === '/' ? '#f8f7f4' : '#ffffff' }}>
        <div className='router-view-wrap'>
          <Suspense>
            <Routes>
              <Route path='/category' exact element={<ModelListByCategory />}></Route>
              <Route path='/category/:category' exact element={<ModelListBySubCategory />}></Route>
              <Route path='/aboutUs' element={<AboutUs />}></Route>
              <Route path='/detail/:id' element={<Detail />}></Route>
              <Route path='/notification' element={<Notification />}></Route>
              <Route path='/UploadPage/Model' element={<UploadModel />}></Route>
              <Route path='/UploadPage/Material' element={<UploadMaterial />}></Route>
              <Route path='/EditModel/:id' element={<UploadModel />}></Route>
              <Route path='/tags/:tag' element={<ModelListByTag />}></Route>
              <Route path='/user/overview/:uid' element={<UserOverview />}></Route>
              <Route path='/user/model' element={<UserModel />}></Route>
              <Route path='/user/collect' element={<UserCollect />}></Route>
              <Route path='/user/history' element={<UserHistory />}></Route>
              <Route path='/user/setting/profile' element={<UserSettingProfile />}></Route>
              <Route path='/user/setting/account' element={<UserSettingAccount />}></Route>
              <Route path='/search' element={<Search />}></Route>
              <Route path='/' element={<Home />}></Route>
            </Routes>
          </Suspense>
        </div>
      </div>
      <Bottom></Bottom>
      <Login open={ showLogin }></Login>
    </>
  )
}

export default App

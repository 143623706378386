import devCfg from './dev'
import testCfg from './test'
import prodCfg from './prod'

const config = {
  dev: devCfg,
  test: testCfg,
  prod: prodCfg,
}

export default config

import './index.scss'
import React, { useRef, useState } from 'react'
import { Modal, Flex } from 'antd'
import { useDispatch } from 'react-redux'
import PhoneLogin from './PhoneLogin'
import PasswordLogin from './PasswordLogin'
import WxLogin from './WxLogin'
import BindPhone from './BindPhone'
import logo from '@/images/Logo.png'
import { setLoginShow } from '@/redux/modules/user/action'

const Login = ({ open }) => {

  const dispatch = useDispatch()
  const wxLoginRef = useRef(null)
  // password wx phone bindPhone
  const [loginType, setLoginType] = useState('phone')
  const [scene, setScene] = useState('')

  const handleLoginTypeChange = (type, scene) => {
    if (type !== 'wx') {
      wxLoginRef.current && wxLoginRef.current.clearStatus()
    }
    if (type === 'bindPhone') {
      setScene(scene)
    }
    setLoginType(type)
  }

  const handleClose = () => {
    wxLoginRef.current && wxLoginRef.current.clearStatus()
    dispatch(setLoginShow(false))
    setLoginType('phone')
  }

  const handleClosed = () => {
    window.location.reload()
  }

  return (
    <>
      <Modal
        wrapClassName="login-modal"
        title={
          <>
            {
              loginType === 'wx'
                &&
                  <Flex className="wx-logo-title" vertical={ true } justify="center" align="center" gap={ 10 }>
                    <div className="title">微信登录</div>
                    <div className="desc">请使用微信扫码关注公众号即可登录</div>
                  </Flex>
            }
            {
              loginType === 'bindPhone'
                &&
                  <Flex className="wx-logo-title" vertical={ true } justify="center" align="center" gap={ 10 }>
                    <div className="title">绑定手机号</div>
                  </Flex>
            }
            {
              loginType !== 'wx' && loginType !== 'bindPhone'
                && 
                  <Flex className="logo-title" justify="center" align="center" gap={ 10 }>
                    <img src={ logo } className="logo" alt="logo" />
                    <div className="title">MetaHub</div>
                  </Flex>
            }
          </>
        }
        width={ 400 }
        open={ open }
        footer={ null }
        onCancel={ handleClose }
        afterClose={ handleClosed }
      >
        { loginType === 'phone' && <PhoneLogin changeLoginType={ handleLoginTypeChange } /> }
        { loginType === 'password' && <PasswordLogin changeLoginType={ handleLoginTypeChange } /> }
        { loginType === 'wx' && <WxLogin ref={ wxLoginRef } changeLoginType={ handleLoginTypeChange } /> }
        { loginType === 'bindPhone' && <BindPhone changeLoginType={ handleLoginTypeChange } scene={ scene } /> }
      </Modal>
    </>
  )
}

export default React.memo(Login)

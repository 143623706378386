import React, { useCallback, useEffect, useState } from 'react'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { downloadFile } from '@/utils/obs'

const UserAvatar = ({ size, avatar, shape, onClick }) => {

  const [userAvatar, setUserAvatar] = useState('')

  const getUserAvatar = useCallback(async () => {
    if (avatar) {
      try {
        const url = await downloadFile(avatar)
        setUserAvatar(url)
      } catch (e) {
        console.log(e)
      }
    }
  }, [avatar])

  useEffect(() => {
    getUserAvatar()
  }, [getUserAvatar])

  return (
    <>
      {
        avatar && userAvatar
          ? <Avatar size={ size } src={ userAvatar } shape={ shape } onClick={ onClick } style={{ cursor: 'pointer' }} />
          : <Avatar size={ size } icon={<UserOutlined />}  shape={ shape } onClick={ onClick } style={{ cursor: 'pointer' }} />
      }
    </>
    
  )
}

export default UserAvatar
import './index.scss'
import React from 'react'
import { Card, Space, Flex, Popover, Skeleton, Dropdown } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import ImageBox from '@/components/ImageBox'
import ModelAction from '@/views/components/ModelAction'
import UserAvatar from '@/components/UserAvatar'
import UserPopup from '@/views/components/UserPopup'

const ModelItem = ({ item, onDropdownClick, avatar = true }) => {

  const titleStyle = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#000",
    minWidth: "60px",
    maxWidth: "180px",
    display: "inline-block",
    overflow: "hidden",
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }

  return (
    <>
      <Card
        className="model-card"
        style={{ cursor: "pointer" }}
        cover={
          <>
            <ImageBox
              src={ item.modelImgUrl }
              loading={ item.loading }
            />
          </>
        }
        onClick={() => {
          item.id && window.open(`/detail/${ item.id }`)
        }}
      >
        <Flex justify="space-between">
          <Skeleton
            className="skeleton-userinfo"
            avatar={
              avatar
                ? {
                    size: 30,
                    shape: 'circle'
                  }
                : false
            }
            title={ true }
            paragraph={ false }
            active={ true }
            loading={ item.loading }
          >
            <Space align="center" className="user-info">
              {
                !onDropdownClick && item.userCard
                  &&  <Popover
                        overlayClassName="user-popover"
                        placement="bottomLeft"
                        content={ <UserPopup data={ item.userCard } /> }
                      >
                        <div>
                          <UserAvatar size={ 30 } avatar={ item.userCard.avatar } />
                        </div>
                      </Popover>
              }
              <div style={titleStyle} title={ item.modelName }>{item.modelName}</div>
            </Space>
            <Space wrap>
              <ModelAction
                actionType={ ['浏览', '点赞', '收藏'] }
                model={ item }
                readonly={ true }
              ></ModelAction>
            </Space>
          </Skeleton>
        </Flex>
        {
          item.status === 0 && <div className="model-status">
                                  <div className="model-status-text">
                                    <span>草稿</span>
                                  </div>
                                </div>
        }
      </Card>
      {
        !item.loading && onDropdownClick && <Flex justify="flex-end" style={{ paddingTop: "5px" }}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "move",
                  label: <span>移动至</span>,
                },
                {
                  key: "delete",
                  label: <span>删除</span>,
                }
              ],
              onClick: ($event) => onDropdownClick($event, item)
            }}
          >
            <MoreOutlined
              rotate={90}
              style={{ color: "#000", fontSize: "22px" }}
            />
          </Dropdown>
        </Flex>
      }
      
    </>
  )
}

export default React.memo(ModelItem)

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Input, Tag, Popover, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { hotSearchRecord } from '@/api'


const SearchBarWithHistory = () => {

  const navigate = useNavigate()
  const [keyword, setKeyword] = useState('')
  const [hotList, setHotList] = useState([])
  const [history, setHistory] = useState([])
  const [showHistory, setShowHistory] = useState(false)

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value)
  }

  const handleKeywordClick = (item) => {
    setKeyword(item)
    navigate('/search?keyword=' + item.trim())
  }

  const handleSearch = () => {
    console.log('handleSearch', keyword)
    if (keyword) {
      navigate('/search?keyword=' + keyword.trim())
      updateHistory(keyword.trim())
    }
  }

  const handleDelete = (field) => {
    const searchHistory = JSON.parse(localStorage.getItem('search_history') || '[]')
    const findIndex = searchHistory.findIndex(item => item === field)
    searchHistory.splice(findIndex, 1)
    const list = Array.from(new Set(searchHistory))
    setHistory(list.slice(0, 20))
    localStorage.setItem('search_history', JSON.stringify(list))
  }

  const updateHistory = (key) => {
    const searchHistory = JSON.parse(localStorage.getItem('search_history') || '[]')
    searchHistory.unshift(key)
    const list = Array.from(new Set(searchHistory))
    setHistory(list.slice(0, 20))
    localStorage.setItem('search_history', JSON.stringify(list.slice(0, 20)))
  }

  const getHotSearchRecord = async () => {
    try {
      const res = await hotSearchRecord()
      if (res.code === 0) {
        setHotList(res.data.map(item => item.keyword))
      } else {
        setHotList([])
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    setHistory(JSON.parse(localStorage.getItem('search_history') || '[]'))
    getHotSearchRecord()
  }, [])

  const History = () => {
    return (
      showHistory && (
        <div style={{ width: "320px", minHeight: "200px" }}>
          <div className="flex align-center" style={{ marginBottom: "10px" }}>
            <h5 style={{ marginBottom: 0, fontSize: "18px" }}>搜索历史</h5>
          </div>
          <div style={{ minHeight: '50px' }}>
            {
              history.length === 0
                ? <p style={{ color: "#666" }}>暂无历史记录</p>
                : history.map((item, index) => (
                    <Tooltip title="点击x号删除记录" arrow={ true }>
                      <Tag
                        closable={ true }
                        onClose={() => handleDelete(item)}
                        key={index}
                        onClick={ () => handleKeywordClick(item) }
                        style={{ marginRight: '8px', marginBottom: '8px', cursor: 'pointer' }}
                      >
                        { item }
                      </Tag>
                    </Tooltip>
                    
                  ))
            }
          </div>
          <div>
            <h5 style={{ marginTop: "20px", fontSize: "18px" }}>
              站内热门搜索
            </h5>
            {hotList && hotList.length === 0 ? (
              <p style={{ color: '#666' }}>暂无热门搜索</p>
            ) : (
              hotList.map((item, index) => (
                <Tag
                  key={index}
                  onClick={ () => handleKeywordClick(item) }
                  style={{ marginRight: '8px', marginBottom: '8px', cursor: 'pointer' }}
                >
                  {item}
                </Tag>
              ))
            )}
          </div>
        </div>
      )
    );
  }

  return (
    <Popover content={<History />} trigger="click">
      <div className="flex">
        <SearchOutlined style={{ fontSize: "18px" }} />
        <Input
          className="search-input"
          placeholder="搜索..."
          value={ keyword }
          allowClear
          style={{ border: "none", height: "48px" }}
          onChange={ handleKeywordChange }
          onPressEnter={ handleSearch }
          onFocus={() => setShowHistory(true)}
        />
      </div>
    </Popover>
  );
};

export default SearchBarWithHistory;

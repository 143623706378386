import React, { useCallback, useEffect, useState } from 'react'
import { downloadFile } from '@/utils/obs'

const ImageLoad = ({ url }) => {

  const [realUrl, setRealUrl] = useState('')

  const getRealUrl = useCallback(async () => {
    const fileUrl = await downloadFile(url)
    if (fileUrl) {
      setRealUrl(fileUrl)
    }
  }, [url])

  useEffect(() => {
    getRealUrl()
  }, [url, getRealUrl])

  return <img src={ realUrl } alt="" style={{ width: '100%', height: '100%' }} />
}

export default ImageLoad

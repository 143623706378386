import './index.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form, Input, Space, Select, Button, Flex, message } from 'antd'
import { wxLoginBindPhone, getPhoneCode } from '@/api'
import {setToken,setLoginShow} from '@/redux/modules/user/action'

let countDownTimer = null

const BindPhone = ({ scene }) => {

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [initForm] = useState({
    mobile: '',
    code: '',
    uid: ''
  })
  let [countdown, setCountdown] = useState(0)

  const handleGetCaptch = async () => {
    try {
      await form.validateFields(['mobile'])
      const res = await getPhoneCode(form.getFieldValue('mobile'))
      if (res.code === 0) {
        form.setFieldsValue({
          uid: res.data
        })
        setCountdown(60)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleFinish = () => {
    handleBind()
  }

  const handleBind = async () => {
    try {
      const res = await wxLoginBindPhone({
        ...form.getFieldsValue(),
        scene: scene
      })
      if (res.code === 0) {
        dispatch(setToken(res.data))
        dispatch(setLoginShow(false))
      } else {
        message.error(res.msg)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (countdown === 60) {
      if (countDownTimer) {
        clearInterval(countDownTimer)
        countDownTimer = null
      }
      countDownTimer = setInterval(() => {
        if (countdown > 0) {
          setCountdown(--countdown)
        } else {
          clearInterval(countDownTimer)
          countDownTimer = null
        }
      }, 1000)
    }
  }, [countdown])

  return (
    <>
      <Form
        className="login-form"
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        form={form}
        name="login-form"
        initialValues={ initForm }
        onFinish={ handleFinish }
        style={{ maxWidth: 450 }}
        size="large"
      >
        <Form.Item
          name="mobile"
          label={ null }
          rules={
            [
              {
                required: true,
                message: '请输入手机号'
              },
              {
                pattern: /^1[3-9]\d{9}$/,
                message: '手机号格式不正确!',
              }
            ]
          }
        >
          <Space.Compact block>
            <Select
              style={{ width: '100px' }}
              defaultValue="+86"
              options={[
                { value: '86', label: '+86' }
              ]}
            />
            <Input placeholder="请输入手机号" />
          </Space.Compact>
        </Form.Item>
        <Form.Item
          name="uid"
          style={{ display: 'none' }}
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="code"
          label={ null }
          rules={
            [
              {
                required: true,
                message: '请输入验证码'
              },
              {
                type: 'string',
                min: 6,
                max: 6,
                message: '请输入6位验证码'
              }
            ]
          }
        >
          <Flex gap={ 15 }>
            <Input placeholder="6位短信验证码" />
            <Button style={{ flex: '0 0 110px' }} onClick={ handleGetCaptch } disabled={ countdown > 0 } >{ countdown > 0 ? `${ countdown }秒` : '获取验证码' }</Button>
          </Flex>
        </Form.Item>
        <Form.Item>
          <Button type="primary" block htmlType="submit" >确认</Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default React.memo(BindPhone)

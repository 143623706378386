import './index.scss'
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch } from 'react-redux'
import { KeyOutlined } from '@ant-design/icons'
import { Form, Image, Button, Flex } from 'antd'
import { getWeChat, checkWeChatStatus } from '@/api'
import { setToken, setLoginShow } from '@/redux/modules/user/action'

let timer = null

const WxLogin = forwardRef(({ changeLoginType }, ref) => {

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [qrcode, setQrcode] = useState('')

  const getWxCode = async () => {
    try {
      const res = await getWeChat()
      setQrcode(res.url)
      startCheckLoginStatus(res.scene_str)
    } catch (e) {
      console.log(e)
    }
  }

  const startCheckLoginStatus = (scene) => {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
    timer = setInterval(async () => {
      const res = await checkWeChatStatus({ sceneStr: scene })
      if (res.data.registered && res.data.scan) {
        dispatch(setToken(res.data.token))
        dispatch(setLoginShow(false))
        clearInterval(timer)
        timer = null
        changeLoginType('phone')
      }
      if (!res.data.registered && res.data.scan) {
        clearInterval(timer)
        timer = null
        changeLoginType('bindPhone', scene)
      }
    }, 2000)
  }

  useImperativeHandle(ref, () => ({
    clearStatus: () => {
      if (timer) {
        clearInterval(timer)
        timer = null
      }
    }
  }))

  useEffect(() => {
    getWxCode()
  }, [])

  return (
    <>
      <Form
        className="login-form"
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        form={form}
        name="login-form"
        style={{ maxWidth: 450 }}
        size="large"
      >
        <Form.Item>
          <Flex justify="center">
            <div className="qrcode-box">
              <Image
                width={200}
                height={200}
                preview={ false }
                src={ qrcode }
              />
            </div>
          </Flex>
          
        </Form.Item>
        <Form.Item style={{ marginBottom: '0' }}>
          <Flex justify="center">
            <Button shape="round" icon={ <KeyOutlined /> } size="middle" onClick={ () => changeLoginType('phone') }>免密码登录</Button>
          </Flex>
        </Form.Item>
      </Form>
    </>
  )
})

export default React.memo(WxLogin)

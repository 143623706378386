import './index.scss'
import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import { useScroll, useDebounceFn } from 'ahooks'
import { FloatButton } from 'antd'

const ScrollLoad = forwardRef(({ children, onReachBottom, loading, loadFinish }, ref) => {

  const scrollLoadRef = useRef(null)
  const scrollWrapRef = useRef(null)
  const backTopRef = useRef(null)

  const { run } = useDebounceFn(
    () => {
      onReachBottom && onReachBottom()
    },
    {
      wait: 20
    }
  )

  useScroll(scrollLoadRef, (e) => {
    const maxScoll = scrollWrapRef.current.offsetHeight - scrollLoadRef.current.offsetHeight
    if (!loadFinish && !loading && e.top > 0 && maxScoll - e.top < 30) {
      run()
    }
  })

  useImperativeHandle(ref, () => ({
    scrollTop: () => {
      scrollLoadRef.current.scrollTo(0, 0)
    }
  }))

  return (
    <div className="scoll-load" ref={ scrollLoadRef }>
      <div className="scroll-wrap" ref={ scrollWrapRef }>
        <div className="children-wrap">
          { children }
        </div>
      </div>
      <FloatButton.BackTop
        ref={ backTopRef }
        visibilityHeight={ 500 }
        duration={100}
        target={ () => scrollLoadRef.current }
      />
    </div>
  )
})

export default ScrollLoad

import QS from 'qs'
import http from '../utils/http'

export function getTypeList() {
  return http.get('/homePage/getFirstCategory')
}

export function addCategory(data) {
  return http.post('/homePage/addCategory', data)
}

export function shareCountAdd(data) {
  return http.post('/homePage/shareCountAdd', data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function getMyCollectModel(data) {
  return http.get('/admin/api/modelInfo/collectPage', {
    params: data,
    paramsSerializer: function (params) {
      return QS.stringify(params, { indices: false })
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}


export function getFirstCategory() {
  return http.get('/homePage/getFirstCategory')
}

export function getSecondCategory(id) {
  return http.get(`/homePage/getSecondCategory/${id}`)
}

export function saveModel(data) {
  return http.post('/admin/api/modelInfo', data, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function updateModel(data) {
  return http.put('/admin/api/modelInfo', data, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
// 获取我的文件夹
export function getMyModelList() {
  return http.get(`/collect/list`)
}
// 新增我的文件夹
export function postMyModelList(data) {
  return http.post(`/collect`,data)
}

import React from 'react';
import { ConfigProvider } from 'antd'
import ReactDOM from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store,persistor } from './redux';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import zhCN from 'antd/es/locale/zh_CN'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider locale={ zhCN }>
          <App />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

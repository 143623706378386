import "./index.scss";
import React, { useEffect, useState } from "react"
import { useNavigate, NavLink, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { logout, setLoginShow } from "../../redux/modules/user/action.js"
import { Avatar, Badge, Popover, Button, message, Flex } from 'antd'
import { UserOutlined } from "@ant-design/icons"
import logo from "../../images/Logo.png"
import Search from "../components/Search"
import ImageLoad from "@/components/ImageLoad"

const Header = () => {
  const location = useLocation()
  const user = useSelector((state) => state.user)
  const notifications = useSelector((state) => state.user.notifications)
  const [unreadCount, setUnreadCount] = useState(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  let handleLogout = () => {
    dispatch(logout())
    navigate('/')
    message.error("退出成功")
  };

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      setUnreadCount(notifications.filter((item) => item.isRead === 0).length)
    }
  }, [notifications])

  const UserInfo = () => {
    return (
      <div
        className="flex flex-direction justify-center align-center"
        style={{ width: "160px" }}
      >
        <Flex vertical justify="center" align="center" flex={ 1 } gap={ 10 }>
          <h2>{ (user && user.userInfo) ? user.userInfo.nickname : '匿名' }</h2>
          <Flex gap={ 10 }>
            <Flex align="center">
              关注：{ user && user.follows ? user.follows.length : 0 }
            </Flex>
            <Flex align="center">
              粉丝：{ user && user.fans ? user.fans.length : 0 }
            </Flex>
          </Flex>
        </Flex>
        <Button
          onClick={() => {
            navigate('/notification')
          }}
          style={{ margin: "5px 0" }}
        >
          个人中心
        </Button>
        <Button onClick={handleLogout} style={{ margin: "5px 0" }}>
          退出登录
        </Button>
      </div>
    );
  };

  return (
    <div
      className="header"
      style={{ background: location.pathname === "/" ? "#f8f7f4" : "#ffffff" }}
    >
      <div className="head_content">
        <div className="left-menu flex align-center">
          <NavLink
            className="flex align-end"
            to="/"
            style={{ marginBottom: "10px" }}
          >
            <img src={logo} className="logo" alt="logo" />
            <h3>MetaHub</h3>
          </NavLink>
          <div className="menu_item flex align-end">
            <NavLink to="/category" id="model-category">模型分类</NavLink>
            <NavLink to="/aboutUs" id="about-us">关于我们</NavLink>
          </div>
        </div>
        <div className="search">
          <Search hotList={[]} />
        </div>
        <div className="right_menu flex align-center">
          {user.isLogin ? (
            <>
              <NavLink className="its" id="my-model" to="/user/model">
                我的模型
              </NavLink>
              <NavLink className="its" to="/user/collect">
                我的收藏
              </NavLink>
              <NavLink className="its" to="/user/history">
                浏览历史
              </NavLink>
              <Popover content={<UserInfo />} trigger="hover">
                <Badge count={ unreadCount }>
                  {user && user.userInfo && user.userInfo.avatar ? (
                    <div
                      style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <ImageLoad url={user.userInfo.avatar} />
                    </div>
                  ) : (
                    <Avatar
                      icon={<UserOutlined />}
                    />
                  )}
                </Badge>
              </Popover>
            </>
          ) : (
            <>
              <span
                onClick={ () => dispatch(setLoginShow(true)) }
                className="register flex-ali"
              >
                登录/注册
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;

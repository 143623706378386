import './index.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import TipImg from './tip.png'

let timer = null

const SiteTip = ({ onClose }) => {

  const [open, setOpen] = useState(localStorage.getItem('openTip') === null ? true : JSON.parse(localStorage.getItem('openTip')))
  const [showClose, setShowClose] = useState(false)
  const [countdown, setCountdown] = useState(3)

  const startTimer = useCallback(() => {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
    timer = setInterval(() => {
      if (countdown > 1) {
        const cd = countdown - 1
        setCountdown(cd)
      } else {
        setShowClose(true)
        clearInterval(timer)
      }
    }, 1000)
  }, [countdown])

  const handleClose = () => {
    setOpen(false)
    localStorage.setItem('openTip', false)
  }

  useEffect(() => {
    if (open) {
      startTimer()
    }
  }, [startTimer])

  return (
    <>
      <Modal
        wrapClassName="tip-modal"
        title={ null }
        width={ 650 }
        open={ open }
        footer={ null }
        onCancel={ onClose }
      >
        <div className="right-corner">
          {
            showClose
              ? <>
                  <CloseOutlined style={{ cursor: 'pointer' }} onClick={ handleClose } />
                </>
              : <div className="countdown">
                  { countdown } 秒后可关闭
                </div>
          }
        </div>
        <img src={ TipImg } alt="" />
      </Modal>
    </>
  )
}

export default React.memo(SiteTip)

import axios from 'axios'
import qs from 'qs'
import { store } from '../redux'
import { logout, setLoginShow } from '@/redux/modules/user/action'
import envCfg from '@/config/env'

// axios实例
const service = axios.create({
	baseURL: envCfg[process.env.REACT_APP_ENV]['API_BASE_URL'],
	timeout: 600000,
	headers: {
		'Content-Type': 'application/json;charset=UTF-8'
	}
})

// 请求拦截器
service.interceptors.request.use(
	(config) => {
		let token = store.getState().user?.token;
		if (token && !config.headers['no-token']) {
			config.headers.Authorization = 'Bearer ' + token
		}
		// config.headers['Accept-Language'] = cache.getLanguage()

		// 追加时间戳，防止GET请求缓存
		if (config.method?.toUpperCase() === 'GET') {
			config.params = {
				...config.params,
				t: new Date().getTime()
			}
		}

		if (Object.values(config.headers).includes('application/x-www-form-urlencoded')) {
			config.data = qs.stringify(config.data)
		}

		return config
	},
	error => {
		return Promise.reject(error)
	}
)

// 响应拦截器
service.interceptors.response.use(
	response => {
		if (response.status !== 200) {
			// return Promise.reject(new Error(response.statusText || 'Error'))
			return response.data
		}

		if (response.config.url == "/api/homePage/downloadModel") {
			return response
		}

		const res = response.data
		// 响应成功
		if (res.code === 0) {
			return res
		}

		// 错误提示

		// 没有权限，如：未登录、登录过期等，需要跳转到登录页
		if (res.code === 401 || res.code === 403) {
			if (store.getState().user) {
				store.dispatch(logout())
			}
		}


		return response.data
	},
	error => {
		if (error.response.status === 403) {
			if (store.getState().user) {
				store.dispatch(logout())
			}
			store.dispatch(setLoginShow(true))
		}
		return Promise.reject(error)
	}
)

// 导出 axios 实例
export default service
// 设置 token
export const SET_TOKEN = "SET_TOKEN";

// 设置 用户信息
export const SET_USER = "SET_USER";
export const SET_FOLLOWS = "SET_FOLLOWS";
export const SET_FANS = "SET_FANS";

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// 搜索
export const SET_SEARCH = "SET_SEARCH";
export const SET_SHOW_LOGIN = "SET_SHOW_LOGIN";

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
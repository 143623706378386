const config = {
  access_key_id: '3CSQNZNSAICUJIYFXW7J',
  secret_access_key: '5kAuDHXNhf2z8yGUr6VJVz1tHtcsKQk8gTcUmmHz',
  server : 'obs.cn-south-1.myhuaweicloud.com',
  downServer: 'cgtrader.obs.cn-south-1.myhuaweicloud.com'
  // access_key_id: 'JLKB8XPKSA6OFLDC8XYD',
  // secret_access_key: 'oEfVJ1m44yfSsiy7sJPJn51lSl1WWe4gsFKbD2sa',
  // server : 'obs.cn-north-4.myhuaweicloud.com',
  // downServer: 'yehan.obs.cn-north-4.myhuaweicloud.com'
}

export default config

import * as types from '../../mutation-types'
// 初始用户状态
const initialState = {
  userInfo: null,
  token: '',
  isLogin: false,
  showLogin: false,
  follows: [],
  fans: [],
  notifications: []
};

// 用户reducer函数
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TOKEN:
      // 处理登录action
      return {
        ...state,
        token: action.token,
        isLogin: true,
      }
    case types.SET_USER:
      // 处理登录action
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case types.LOGIN:
      // 处理登录action
      return {
        ...state,
        isLogin: true,
      };
    case types.LOGOUT:
      // 处理登出action
      return {
        ...state,
        token: '',
        userInfo: null,
        isLogin: false,
        follows: [],
        fans: [],
        notifications: []
      };
    case types.SET_SHOW_LOGIN:
      // 处理登录action
      return {
        ...state,
        showLogin: action.status,
      };
    case types.SET_FOLLOWS:
      return {
        ...state,
        follows: action.follows,
      }
    case types.SET_FANS:
      return {
        ...state,
        fans: action.fans,
      }
    case types.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
      }
    default:
      // 对于未知的action类型，返回当前的state
      return state
  }
};

export default userReducer;
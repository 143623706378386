import http from '../utils/http'

// 密码登录
export function passwordLogin(data) {
  return http.post('/user/login', data)
}

// 发送验证码
export function getPhoneCode(mobile) {
  return http.post(`/user/sms/sendCode/${mobile}`)
}
// 手机验证码登录
export function phoneLogin(data) {
  return http.post(`/user/sms/login`, data)
}

// 获取图形验证码
export function getCode() {
  return http.get('/user/captcha', '', {})
}

// 生成微信二维码
export function getWeChat() {
  return http.get('/wx/generate','', {})
}

// 检查扫码微信状态
export function checkWeChatStatus(data) {
  return http.get('/user/checkQRCode',{
    params: data
  })
}

// 微信扫码登录并绑定手机号
export function wxLoginBindPhone(data) {
  return http.post('/user/wxLoginBindPhone', data)
}

// 获取绑定微信二维码
export function getWeChatBindCode() {
  return http.get('/wx/getWxBindCode')
}

// 检查扫码微信状态
export function checkWeChatBindStatus(data) {
  return http.get('/user/checkBindQRCode',{
    params: data
  })
}

// 初始化密码
export function initUsername(data) {
  return http.post('/user/initUsername', data)
}

// 初始化密码
export function initPassword(data) {
  return http.post('/user/initPassword', data)
}

// 修改密码
export function updatePassword(data) {
  return http.post('/user/updatePassword', data)
}

// 修改密码
export function bindPhone(data) {
  return http.post('/user/bindPhone', data)
}

export function getBarrageTagList(data) {
  return http.get('/modelBarrageTag/list', {
    params: data
  })
}

export function saveBarrageTag(data) {
  return http.post('/modelBarrageTag', data)
}

export function getRelateModel(data) {
  return http.get('/modelBarrageTag/relateModel', {
    params: data
  })
}

export function getEnvHdrPage(data) {
  return http.post('/envHdr/page', data)
}

// 保存模型草稿
export function saveDraft(data) {
  return http.post('/model/saveDraft', data)
}

// 发布模型
export function publishModel(data) {
  return http.post('/model/publish', data)
}

// 修改模型草稿
export function updateDraft(data) {
  return http.post('/model/updateDraft', data)
}

// 修改模型
export function updateModel(data) {
  return http.post('/model/updateModel', data)
}

// 我的模型
export function getMyModel(data) {
  return http.post('/model/user', data)
}

// 模型删除
export function deleteModel(data) {
  return http.post('/model/delete', data)
}

// 相关模型
export function getRelatedModes(data) {
  return http.post('/model/related', data)
}

// 模型用户信息
export function getModelUserInfo(data) {
  return http.get('/model/modelUserInfo', {
    params: data
  })
}

// 根据标签查询模型列表
export function modelPageByTag(data) {
  return http.post('/model/pageByTag', data)
}

// 根据分类查询模型列表
export function modelPageByCategory(data) {
  return http.post('/model/pageByCategory', data)
}

// 模型详细信息
export function getModelDetail(id) {
  return http.get(`/model/detail/${id}`)
}

export function getTypeList() {
  return http.get('/homePage/getFirstCategory')
}

export function saveMaterial(data) {
  return http.post('/material/save', data)
}

// 材质分页
export function getMaterial(data) {
  return http.post('/material/page', data)
}

// 我的材质
export function getMyMaterial(data) {
  return http.post('/material/my', data)
}

// 材质删除
export function deleteMaterial(data) {
  return http.post('/material/delete', data)
}

export function updateUserInfo(data) {
  return http.post('/user/update', data)
}

// 用户信息
export function getUserinfo() {
  return http.get('/user/info', '', {})
}

// 关注、取消关注
export function toggleFollow(data) {
  return http.post('/user/follow', data)
}

// 评论
export function sendComment(data) {
  return http.post('/comment', data)
}

// 评论树
export function getCommentTree(modelId) {
  return http.get(`/comment/tree/${modelId}`)
}

// 个人中心信息
export function getUserCenter(data = {}) {
  return http.get('/user/center', {
    params: data
  })
}

// 获取消息
export function getNotices(data) {
  return http.get('/sys/user/center/notify/page', {
    params: data
  })
}

// 获取关注列表
export function getFollowList() {
  return http.get('/sys/user/center/followList')
}

// 获取粉丝列表
export function getFanList() {
  return http.get('/sys/user/center/fanList')
}

// 我的关注
export function getMyFollows() {
  return http.get('/user/myFollows')
}

// 我的粉丝
export function getMyFans() {
  return http.get('/user/myFans')
}

// 获取标签列表
export function getTagsList(data = {}) {
  return http.post('/tags/list', data)
}

// 用户的关注
export function getUserFollows(id) {
  return http.get(`/user/userFollows/${id}`)
}

// 用户的粉丝
export function getUserFans(id) {
  return http.get(`/user/userFans/${id}`)
}

// 模型文件上传
export function uploadModelFile(data) {
  return http.post('/fileUpload/model', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 模型封面上传
export function uploadModelCover(data) {
  return http.post('/fileUpload/modelCover', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 材质文件上传
export function uploadMaterialFile(data) {
  return http.post('/fileUpload/material', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 材质封面上传
export function uploadMaterialCover(data) {
  return http.post('/fileUpload/materialCover', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}


// 下载检查
export function userDownloadCheck(modelId) {
  return http.get(`/user/downloadCheck/${modelId}`)
}

// 首页模型
export function getHomeModels(data) {
  return http.post('/homePage/homeModels', data)
}

// 首页模型
export function deleteComment(id) {
  return http.get(`/comment/delete/${id}`)
}

// 首页模型
export function feedbackCommit(data) {
  return http.post('/feedback/commit', data)
}

// 模型搜索
export function keywordModels(data) {
  return http.post('/search/keywordModels', data)
}

// 首页模型
export function homeModels(data) {
  return http.post('/search/homeModels', data)
}

// 分类模型
export function categoryModels(data) {
  return http.post('/search/categoryModels', data)
}

// 分类模型
export function subCategoryModels(data) {
  return http.post('/search/subCategoryModels', data)
}

// 标签模型
export function tagModels(data) {
  return http.post('/search/tagModels', data)
}

// 相关模型
export function relatedModels(data) {
  return http.post('/search/relatedModels', data)
}

// 用户模型
export function userModels(data) {
  return http.post('/search/userModels', data)
}

// 热门搜索记录
export function hotSearchRecord(data) {
  return http.post('/searchRecord/hot', data)
}

// 模型点赞列表
export function modelThumbPage(data) {
  return http.post('/model/thumbPage', data)
}

// 模型收藏列表
export function modelCollectPage(data) {
  return http.post('/model/collectPage', data)
}

// 模型浏览列表
export function modelViewPage(data) {
  return http.post('/model/viewPage', data)
}

// 模型点赞
export function thumbModel(data) {
  return http.post('/model/thumbModel', data)
}

// 模型收藏
export function collectModel(data) {
  return http.post('/model/collectModel', data)
}

// 收藏夹列表
export function collectDirList(data) {
  return http.post('/collectDir/list', data)
}

// 创建收藏夹
export function createCollectDir(data) {
  return http.post('/collectDir/create', data)
}

// 移动模型到收藏夹
export function updateModelCollectDir(data) {
  return http.post('/model/updateCollectDir', data)
}

// 清楚浏览记录
export function clearViewRecord() {
  return http.post('/model/clearViewRecord')
}

// 心跳检测
export function userHeartbeat() {
  return http.post('/user/heartbeat')
}

// 消息列表
export function getNotificationList() {
  return http.get('/notification/getList')
}

// 消息标记已读
export function markNotificationRead(data) {
  return http.post('/notification/markRead', data)
}

// 埋点数据上报接口
export function userActivityReport(data) {
  return http.post('/userActivity/report', data)
}

import './index.scss'
import { Row, Col, Empty, Divider, Tour } from 'antd'
import { useCallback, useEffect, useState } from "react"
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { homeModels, userActivityReport } from '@/api'
import ModelItem from '@/components/ModelItem'
import SiteTip from '@/views/components/SiteTip'
import ScrollLoad from '@/components/ScrollLoad'
import { useDebounceFn } from 'ahooks'

const Home = () => {

  const navigate = useNavigate()
  const [pager, setPager] = useState({
    page: 1,
    limit: 20,
    total: 0
  })
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const user = useSelector((state) => state.user.userInfo)
  const [showTour, setShowTour] = useState(false)

  const fetchList = async () => {
    let oldList = [...list]
    if (pager.page === 1) {
      oldList = []
    }
    oldList = oldList.concat(Array.from({ length: 12 }).fill({ loading: true }))
    setList(oldList)
    setLoading(true)
    try {
      const res = await homeModels({
        page: pager.page,
        limit: pager.limit
      })
      if (res.code === 0 && res.data.list && res.data.list.length > 0) {
        oldList.splice(oldList.length - 12, 12, ...res.data.list)
        const param = { ...pager }
        param.total = res.data.total
        setPager(param)
        setList([...oldList])
      } else {
        const param = { ...pager }
        param.total = 0
        setPager(param)
        setList([])
      }
      setLoading(false)
    } catch (e) {
      setList([])
      setLoading(false)
    }
  }

  const handleReachBottom = useCallback(() => {
    const param = { ...pager }
    param.page++
    setPager(param)
  }, [pager])

  const handleJump = () => {
    navigate('/category')
  }

  const { run } = useDebounceFn(
    async () => {
      let res = null
      try {
        res = await fetch('https://ip.useragentinfo.com/json').then(response => response.json())
      } catch (e) {
        console.log(e)
      }
      userActivityReport({
        userId: (user && user.id) ? user.id : null,
        userIp: res ? res.ip : null,
        pageUrl: window.location.pathname,
        activityDate: new Date().toISOString().split('T')[0],
        country: res ? res.country : '',
        province: res ? res.province : '',
        city: res ? res.city : '',
        area: res ? res.area : ''
      })
    },
    {
      wait: 1000
    }
  )

  const tourSteps = [
    {
      title: '各种类型的模型',
      description: '这里有20种常见类别的模型噢',
      target: () => document.getElementById('model-category')
    },
    {
      title: '用户反馈',
      description: '关于我们中可以给官方留言！',
      target: () => document.getElementById('about-us')
    },
    {
      title: '材质、模型上传',
      description: '这里可以上传本地的模型和材质到云端噢，方便分享给其他人或随时随地查看模型',
      target: () => document.getElementById('my-model')
    }
  ]

  useEffect(() => {
    run()
  }, [])

  useEffect(() => {
    if (user && user.id && !loading) {
      setShowTour(localStorage.getItem('homeTour') === null ? true : JSON.parse(localStorage.getItem('homeTour')))
    }
  }, [user, loading])

  useEffect(() => {
    fetchList()
  }, [pager.page, pager.limit])

  return (
    <>
      <ScrollLoad
        loading={ loading }
        loadFinish={ pager.page >= Math.ceil((pager.total / pager.limit)) }
        onReachBottom={ handleReachBottom }
      >
        <div className="home">
          <div className="banner flex flex-direction align-center">
            <div className="gd_text">
              <div className="trans_color">
                <div className="marquee-content">
                Over 10 million ready to work 3D models!  超过1000万个优质3D模型免费下载！
                </div>
              </div>
            </div>
            <h2>Where This World Store 3D Models</h2>
            <p>畅游多元3D世界，分享你的精彩宇宙</p>
            <div className="start" onClick={ handleJump }>即刻开始!</div>
          </div>
          <div className="px-8 flex-1">
            <Row gutter={ [20, 20] } style={{ marginBottom: '30px' }}>
              {
                list && list.length > 0 && list.map((model, index) => {
                  return (
                    <Col key={ `model_${index}` } xs={24} sm={12} md={8} lg={6}>
                      <ModelItem
                        key={ `model_${index}` }
                        item={ model }
                      />
                    </Col>
                  )
                })
              }
              {
                !loading
                  && <>
                      {
                        !list || list.length === 0
                          ? <Col span={ 24 }><Empty description="暂无模型数据" /></Col>
                          : null
                      }
                      {
                        pager.page <= Math.floor((pager.total / pager.limit))
                          ? null
                          : list && list.length > 0 && <Col span={ 24 } style={{ marginTop: '20px', marginBottom: '20px' }}><Divider plain>没有更多数据啦！</Divider></Col>
                      }
                    </>
              }
            </Row>
          </div>
          <SiteTip />
        </div>
      </ScrollLoad>
      <Tour
        open={ showTour }
        onClose={() => setShowTour(false)}
        steps={ tourSteps }
        onFinish={
          () => {
            setShowTour(false)
            localStorage.setItem('homeTour', false)
          }
        }
      />
    </>
  )
}

export default Home

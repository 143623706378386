import ObsClient from 'esdk-obs-browserjs/src/obs'
import config from '@/config/hwobs.config'

const obs = new ObsClient({
  access_key_id: config.access_key_id,
  secret_access_key: config.secret_access_key,
  server: config.server,
  timeout: 60 * 5
})

export const downloadFile = async (url, callback) => {
  if (!url) {
    return ''
  }
  try {
    const result = await obs.getObject({
      Bucket: 'cgtrader',
      Key: decodeURIComponent(new URL(url).pathname.slice(1)),
      SaveByType: 'file',
      ProgressCallback: callback
    })
    if (result.InterfaceResult.Content.SignedUrl) {
      return result.InterfaceResult.Content.SignedUrl
    } else {
      return ''
    }
  } catch (e) {
    console.log(e)
    return ''
  }
}

export const uploadFile = async (file, callback) => {
  const [fileName, fileType] = file.name.split('.')
  const key = `${fileName}_${new Date().getTime()}.${fileType}`
  try {
    const result = await obs.putObject({
      Bucket: 'cgtrader',
      Key: `model/${key}`,
      SourceFile: file,
      ProgressCallback: callback
    })
    if (result.CommonMsg.Status < 300) {
      return {
        status: true,
        message: '上传成功',
        key: `https://${config.downServer}/model/${key}`
      }
    } else {
      return {
        status: false,
        message: result.CommonMsg.Message
      }
    }
  } catch (e) {
    return {
      status: true,
      message: '上传失败'
    }
  }
}

export const uploadAvatar = async (file, callback) => {
  const [fileName, fileType] = file.name.split('.')
  const key = `${fileName}_${new Date().getTime()}.${fileType}`
  try {
    const result = await obs.putObject({
      Bucket: 'cgtrader',
      Key: `avatar/${key}`,
      SourceFile: file,
      ProgressCallback: callback
    })
    if (result.CommonMsg.Status < 300) {
      return {
        status: true,
        message: '上传成功',
        key: `https://${config.downServer}/avatar/${key}`
      }
    } else {
      return {
        status: false,
        message: result.CommonMsg.Message
      }
    }
  } catch (e) {
    return {
      status: true,
      message: '上传失败'
    }
  }
}

export const uploadBase64File = async (name, base64Data, callback) => {
  const [fileName] = name.split('.')
  const key = `${fileName}_${new Date().getTime()}.png`
  try {
    const result = await obs.putObject({
      Bucket: 'cgtrader',
      Key: `cover/${key}`,
      Body: base64ToBlob(base64Data),
      ProgressCallback: callback
    })
    if (result.CommonMsg.Status < 300) {
      return {
        status: true,
        message: '上传成功',
        key: `https://${config.downServer}/cover/${key}`
      }
    } else {
      return {
        status: false,
        message: result.CommonMsg.Message
      }
    }
  } catch (e) {
    console.log(e)
    return {
      status: true,
      message: '上传失败'
    }
  }
}

export const uploadBlobMaterialFile = async (name, blob, callback) => {
  const [fileName, fileType] = name.split('.')
  const key = `${fileName}.${fileType}`
  try {
    const result = await obs.putObject({
      Bucket: 'cgtrader',
      Key: `material/${key}`,
      Body: blob,
      ProgressCallback: callback
    })
    if (result.CommonMsg.Status < 300) {
      return {
        status: true,
        message: '上传成功',
        key: `https://${config.downServer}/material/${key}`
      }
    } else {
      return {
        status: false,
        message: result.CommonMsg.Message
      }
    }
  } catch (e) {
    return {
      status: true,
      message: '上传失败'
    }
  }
}

export const uploadHdrFile = async (file) => {
  const [fileName, fileType] = file.name.split('.')
  const key = `${fileName}_${new Date().getTime()}.${fileType}`
  try {
    const result = await obs.putObject({
      Bucket: 'cgtrader',
      Key: `envHdr/${key}`,
      SourceFile: file
    })
    if (result.CommonMsg.Status < 300) {
      return {
        status: true,
        message: '上传成功',
        key: `https://${config.downServer}/envHdr/${key}`
      }
    } else {
      return {
        status: false,
        message: result.CommonMsg.Message
      }
    }
  } catch (e) {
    return {
      status: true,
      message: '上传失败'
    }
  }
}

function base64ToBlob(base64) {
  let byteString = atob(base64.split(',')[1])
  let mimeString = base64.split(',')[0].split(':')[1].split(';')[0]
  let ab = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], {type: mimeString})
}

import JSZip from 'jszip'
import { message } from 'antd'
import axios from 'axios'
/**
 * 获取第一个表格的可视化高度
 * @param {*} extraHeight 额外的高度(表格底部的内容高度 Number类型,默认为74)
 * @param {*} id 当前页面中有多个table时需要制定table的id
 */
 export function getTableScroll({ extraHeight, id }) {
  if (typeof extraHeight == "undefined") {
    //  默认底部分页64 + 边距 40 
    extraHeight = 104
  }
  let tHeader = null
  if (id) {
    tHeader = document.getElementById(id) ? document.getElementById(id).getElementsByClassName("ant-table-thead")[0] : null
  } else {
    tHeader = document.getElementsByClassName("ant-table-thead")[0]
  }
  //表格内容距离顶部的距离
  let tHeaderBottom = 0
  if (tHeader) {
    tHeaderBottom = tHeader.getBoundingClientRect().bottom
  }
  //窗体高度-表格内容顶部的高度-表格内容底部的高度
  // let height = document.body.clientHeight - tHeaderBottom - extraHeight
  let height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`
  return height
}

export function copyLink(text){
  // 复制关键字
  var oInput = document.createElement('input');
  oInput.value = text;
  document.body.appendChild(oInput);
  oInput.select(); // 选择对象
  document.execCommand("Copy"); // 执行浏览器复制命令
  oInput.className = 'oInput';
  oInput.style.display='none';
  message.success('复制链接成功, 快去分享吧')
}

export function parseTime(time, cFormat = '{y}-{m}-{d} {h}:{i}:{s}') {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

export function formatBytes(bytes) {
  if (bytes < 1024) return bytes + ' Bytes'
  else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB'
  else if (bytes < 1073741824) return (bytes / 1048576).toFixed(2) + ' MB'
  else return (bytes / 1073741824).toFixed(2) + ' GB'
}

export function parseZip(file) {
  return new Promise(resolve => {
    const jsZip = new JSZip()
    const reader = new FileReader()
    reader.onload = async (event) => {
      
      const { files } = await jsZip.loadAsync(event.target.result)

      const fileList = []
      const itemList = Object.values(files)
      for (let i = 0; i < itemList.length; i++) {
        if (!itemList[i].dir) {
          const item = itemList[i]
          const fileName = item.name.split('/').pop()
          const blob = await item.async('blob')
          fileList.push({
            name: fileName,
            blob: blob,
            size: formatBytes(blob.size)
          })
        }
      }
      resolve(fileList)
    }
    reader.readAsArrayBuffer(file)
  })
}

export function downFileToBlob(url) {
  return new Promise((resolve) => {
    fetch(url).then(async (response) => {
      const fileName = url.replace(/\?.*$/, '').split('/').pop()
      const buffer = await response.arrayBuffer()
      const blob = new Blob([buffer], { type: 'application/octet-stream' })
      blob.name = fileName
      resolve(blob)
    })
  })
}

export function formatNumber(num) {
  if (!num && num != 0) {
    return '0'
  }
  if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'm'
  }
  if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k'
  }
  return num.toString()
}

export function base64ToBlob(base64) {
  let byteString = atob(base64.split(',')[1])
  let mimeString = base64.split(',')[0].split(':')[1].split(';')[0]
  let ab = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], {type: mimeString})
}

import './index.scss'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setLoginShow } from '@/redux/modules/user/action.js'
import { message } from 'antd'
import {
  EyeOutlined,
  MessageOutlined,
  LikeOutlined,
  StarOutlined,
  ShareAltOutlined,
  CloudDownloadOutlined,
  StarFilled,
  LikeFilled
} from '@ant-design/icons'
import { shareCountAdd } from '@/api/api'
import { collectModel, thumbModel } from '@/api'

const ModelAction = ({ user, actionType, model, callback, modelUserInfo, readonly }) => {

  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage()

  // 点赞
  const handleLike = async (e) => {
    e.stopPropagation()
    if (readonly) {
      return
    }
    if (!user || !user.isLogin) {
      dispatch(setLoginShow(true))
      return
    }
    if (modelUserInfo && modelUserInfo.isPraise) {
      message.warning('已经点过赞了')
      return
    }
    try {
      const res = await thumbModel({
        modelId: model.id,
      })
      if (res.msg === 'success') {
        messageApi.success('已点赞')
        callback('点赞')
      }
    } catch (e) {
      console.log(e)
    }
  }

  // 收藏
  const handleCollect = async (e) => {
    e.stopPropagation()
    if (readonly) {
      return
    }
    if (!user || !user.isLogin) {
      dispatch(setLoginShow(true))
      return
    }

    if (modelUserInfo && modelUserInfo.isCollection) {
      message.warning('已经收藏过了')
      return
    }

    try {
      const res = await collectModel({
        modelId: model.id
      })
      if (res.code === 0) {
        message.success('已收藏')
        callback('收藏')
      }
    } catch (e) {
      console.log(e)
    }
  }

  // 分享
  const handleShare = async (e) => {
    e.stopPropagation()
    if (readonly) {
      return
    }
    if (!user || !user.isLogin) {
      dispatch(setLoginShow(true))
      return
    }
    try {
      const res = await shareCountAdd({
        modelId: model.id
      })
      if (res.msg === 'success') {
        const url = window.location.href
        navigator.clipboard.writeText(url).then(() => {
          message.success('分享成功，已复制模型链接')
          callback('分享')
        })
      }
    } catch (e) {
      console.log(e)
    }
    
  }

  return (
    <>
      {contextHolder}
      <div className="model-action">
        {
          actionType.includes('浏览')
            ? <div className="action-item" title='浏览' style={{ cursor: 'default' }}>
              <EyeOutlined />
              <span>{model.modelViewNum || 0}</span>
            </div>
            : null
        }
        {
          actionType.includes('评论')
            ? <div className="action-item" title='评论' style={{ cursor: 'default' }}>
              <MessageOutlined />
              <span>{model.modelCommentNum || 0}</span>
            </div>
            : null
        }
        {
          actionType.includes('点赞')
            ? <div className="action-item" title='点赞' onClick={handleLike}>
              { modelUserInfo && modelUserInfo.isPraise ? <LikeFilled style={{ color: '#ffbf6b' }} /> : <LikeOutlined /> }
              <span>{model.modelPraiseNum || 0}</span>
            </div>
            : null
        }
        {
          actionType.includes('收藏')
            ? <div className="action-item" title='收藏' onClick={handleCollect}>
              { modelUserInfo && modelUserInfo.isCollection ? <StarFilled style={{ color: '#fff81d' }} /> : <StarOutlined /> }
              <span>{model.modelCollectNum || 0}</span>
            </div>
            : null
        }
        {
          actionType.includes('分享')
            ? <div className="action-item" title='分享' onClick={handleShare}>
              <ShareAltOutlined />
              <span>{model.modelShareNum || 0}</span>
            </div>
            : null
        }
        {
          actionType.includes('下载')
            ? <div className="action-item" style={{ cursor: 'default' }}>
              <CloudDownloadOutlined />
              <span>{model.modelDownloadNum || 0}</span>
            </div>
            : null
        }
      </div>
    </>
  )
}

export default ModelAction

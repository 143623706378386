import * as types from '../../mutation-types'

export const setToken = (token) => ({
  type: types.SET_TOKEN,
  token
});

export const setUser = (userInfo) => ({
  type: types.SET_USER,
  userInfo
});


export const login = (userData) => ({
  type: types.LOGIN,
  payload: userData,
});

export const logout = () => ({
  type: types.LOGOUT,
})

export const setLoginShow = (status) => ({
  type: types.SET_SHOW_LOGIN,
  status
})

export const setMyFollows = (follows) => ({
  type: types.SET_FOLLOWS,
  follows
})

export const setMyFans = (fans) => ({
  type: types.SET_FANS,
  fans
})

export const setNotifications = (notifications) => ({
  type: types.SET_NOTIFICATIONS,
  notifications
})

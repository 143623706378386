import './index.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { KeyOutlined, WechatOutlined } from '@ant-design/icons'
import { Form, Input, Space, Select, Button, Flex, Checkbox, Divider, Modal, message } from 'antd'
import { getPhoneCode, phoneLogin } from '@/api'
import { setToken, setLoginShow } from '@/redux/modules/user/action'

let countDownTimer = null

const PhoneLogin = ({ changeLoginType }) => {

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [initForm] = useState({
    mobile: '',
    code: '',
    uid: ''
  })
  let [countdown, setCountdown] = useState(0)

  const [agreement, setAgreement] = useState(false)

  const handleAgreementChange = (e) => {
    setAgreement(!agreement)
  }

  const handleGetCaptch = async () => {
    try {
      await form.validateFields(['mobile'])
      const res = await getPhoneCode(form.getFieldValue('mobile'))
      if (res.code === 0) {
        form.setFieldsValue({
          uid: res.data
        })
        setCountdown(60)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleFinish = () => {
    if (!agreement) {
      Modal.confirm({
        title: '服务协议及隐私保护',
        content: <div className="agreement-content">
          为更好地保护你的合法权益，请阅读并同意
          <Button type="link" className="agreement" onClick={ () => window.open('/license.html', '_blank') }> 最终用户许可协议 </Button>
            和
          <Button type="link" className="agreement" onClick={ () => window.open('/privacy.html', '_blank') }> 隐私协议 </Button>
        </div>,
        okText: '同意',
        cancelText: '不同意',
        onOk() {
          setAgreement(true)
          handleLogin()
        }
      })
    } else {
      handleLogin()
    }
  }

  const handleLogin = async () => {
    try {
      const res = await phoneLogin({
        ...form.getFieldsValue()
      })
      if (res.code === 0) {
        dispatch(setToken(res.data))
        dispatch(setLoginShow(false))
      } else {
        message.error(res.msg)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleChangeWxLogin = () => {
    if (!agreement) {
      Modal.confirm({
        title: '服务协议及隐私保护',
        content: <div className="agreement-content">
          为更好地保护你的合法权益，请阅读并同意
          <Button type="link" className="agreement" onClick={ () => window.open('/license.html', '_blank') }> 最终用户许可协议 </Button>
            和
          <Button type="link" className="agreement" onClick={ () => window.open('/privacy.html', '_blank') }> 隐私协议 </Button>
        </div>,
        okText: '同意',
        cancelText: '不同意',
        onOk() {
          setAgreement(true)
          changeLoginType('wx')
        }
      })
    }
  }

  useEffect(() => {
    if (countdown === 60) {
      if (countDownTimer) {
        clearInterval(countDownTimer)
        countDownTimer = null
      }
      countDownTimer = setInterval(() => {
        if (countdown > 0) {
          setCountdown(--countdown)
        } else {
          clearInterval(countDownTimer)
          countDownTimer = null
        }
      }, 1000)
    }
  }, [countdown])

  return (
    <>
      <Form
        className="login-form"
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        form={form}
        name="login-form"
        initialValues={ initForm }
        onFinish={ handleFinish }
        style={{ maxWidth: 450 }}
        size="large"
      >
        <Form.Item
          name="mobile"
          label={ null }
          rules={
            [
              {
                required: true,
                message: '请输入手机号'
              },
              {
                pattern: /^1[3-9]\d{9}$/,
                message: '手机号格式不正确!',
              }
            ]
          }
        >
          <Space.Compact block>
            <Select
              style={{ width: '100px' }}
              defaultValue="+86"
              options={[
                { value: '86', label: '+86' }
              ]}
            />
            <Input placeholder="请输入手机号" />
          </Space.Compact>
        </Form.Item>
        <Form.Item
          name="uid"
          style={{ display: 'none' }}
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="code"
          label={ null }
          rules={
            [
              {
                required: true,
                message: '请输入验证码'
              },
              {
                type: 'string',
                min: 6,
                max: 6,
                message: '请输入6位验证码'
              }
            ]
          }
        >
          <Flex gap={ 15 }>
            <Input placeholder="6位短信验证码" />
            <Button style={{ flex: '0 0 110px' }} onClick={ handleGetCaptch } disabled={ countdown > 0 } >{ countdown > 0 ? `${ countdown }秒` : '获取验证码' }</Button>
          </Flex>
        </Form.Item>
        <Form.Item style={{ marginBottom: '8px' }}>
          <Button type="primary" block htmlType="submit" >登录 / 注册</Button>
        </Form.Item>
        <Form.Item style={{ marginBottom: '15px' }}>
          <Flex justify="center">
            <Checkbox
              checked={ agreement }
              onChange={ handleAgreementChange }
            >
              我已阅读并同意
              <Button type="link" className="agreement" onClick={ () => window.open('/license.html', '_blank') }> 最终用户许可协议 </Button>
              和
              <Button type="link" className="agreement" onClick={ () => window.open('/privacy.html', '_blank') }> 隐私协议 </Button>
            </Checkbox>
          </Flex>
        </Form.Item>
        <Form.Item style={{ marginBottom: '0', marginTop: '0' }}>
          <Divider plain>其他登录方式</Divider>
        </Form.Item>
        <Form.Item style={{ marginBottom: '0' }}>
          <Flex justify="center" gap={ 10 }>
            <Button shape="round" icon={ <KeyOutlined /> } size="middle" onClick={ () => changeLoginType('password') }>密码登录</Button>
            <Button
              shape="circle"
              icon={ <WechatOutlined /> }
              size="middle"
              style={{ backgroundColor: '#3cb034', color: '#fff' }}
              onClick={ handleChangeWxLogin }
            ></Button>
          </Flex>
        </Form.Item>
      </Form>
    </>
  )
}

export default React.memo(PhoneLogin)

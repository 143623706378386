import './index.scss'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { WechatOutlined, MobileOutlined } from '@ant-design/icons'
import { Form, Input, Space, Button, Flex, Checkbox, Divider, Modal, message } from 'antd'
import Code from './Code'
import { passwordLogin } from '@/api'
import { setToken, setLoginShow } from '@/redux/modules/user/action'


const PasswordLogin = ({ changeLoginType }) => {

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [initForm] = useState({
    username: '',
    password: '',
    key: '',
    captcha: ''
  })

  const getKey = (key) => {
    form.setFieldsValue({
      key
    })
  }

  const [agreement, setAgreement] = useState(false)

  const handleAgreementChange = (e) => {
    setAgreement(!agreement)
  }

  const handleFinish = () => {
    if (!agreement) {
      Modal.confirm({
        title: '服务协议及隐私保护',
        content: <div className="agreement-content">
          为更好地保护你的合法权益，请阅读并同意
          <Button type="link" className="agreement" onClick={ () => window.open('/license.html', '_blank') }> 最终用户许可协议 </Button>
            和
          <Button type="link" className="agreement" onClick={ () => window.open('/privacy.html', '_blank') }> 隐私协议 </Button>
        </div>,
        okText: '同意',
        cancelText: '不同意',
        onOk() {
          setAgreement(true)
          handleLogin()
        }
      })
    } else {
      handleLogin()
    }
  }

  const handleLogin = async () => {
    try {
      const res = await passwordLogin({
        ...form.getFieldsValue()
      })
      if (res.code === 0) {
        dispatch(setToken(res.data))
        dispatch(setLoginShow(false))
      } else {
        message.error(res.msg)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleChangeWxLogin = () => {
    if (!agreement) {
      Modal.confirm({
        title: '服务协议及隐私保护',
        content: <div className="agreement-content">
          为更好地保护你的合法权益，请阅读并同意
          <Button type="link" className="agreement" onClick={ () => window.open('/license.html', '_blank') }> 最终用户许可协议 </Button>
            和
          <Button type="link" className="agreement" onClick={ () => window.open('/privacy.html', '_blank') }> 隐私协议 </Button>
        </div>,
        okText: '同意',
        cancelText: '不同意',
        onOk() {
          setAgreement(true)
          changeLoginType('wx')
        }
      })
    } else {
      changeLoginType('wx')
    }
  }

  return (
    <>
      <Form
        className="login-form"
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        form={form}
        name="login-form"
        initialValues={ initForm }
        onFinish={ handleFinish }
        style={{ maxWidth: 450 }}
        size="large"
      >
        <Form.Item
          name="username"
          rules={
            [
              {
                required: true,
                message: '请输入用户名'
              }
            ]
          }
        >
          <Space.Compact block>
            <Input placeholder="请输入用户名" />
          </Space.Compact>
        </Form.Item>
        <Form.Item
          name="password"
          rules={
            [
              {
                required: true,
                message: '请输入密码'
              }
            ]
          }
        >
          <Space.Compact block>
            <Input.Password placeholder="请输入密码" />
          </Space.Compact>
        </Form.Item>
        <Form.Item
          name="key"
          style={{ display: 'none' }}
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="captcha"
          rules={
            [
              {
                required: true,
                message: '请输入验证码'
              }
            ]
          }
        >
          <Flex gap={ 15 }>
            <Input placeholder="请输入验证码" />
            <Code getKey={ getKey } />
          </Flex>
        </Form.Item>
        <Form.Item style={{ marginBottom: '8px' }}>
          <Button type="primary" block htmlType="submit" >登录</Button>
        </Form.Item>
        <Form.Item style={{ marginBottom: '15px' }}>
          <Flex justify="center">
            <Checkbox
              checked={ agreement }
              onChange={ handleAgreementChange }
            >
              我已阅读并同意
              <Button type="link" className="agreement" onClick={ () => window.open('/license.html', '_blank') }> 最终用户许可协议 </Button>
              和
              <Button type="link" className="agreement" onClick={ () => window.open('/privacy.html', '_blank') }> 隐私协议 </Button>
            </Checkbox>
          </Flex>
        </Form.Item>
        <Form.Item style={{ marginBottom: '0', marginTop: '0' }}>
          <Divider plain>其他登录方式</Divider>
        </Form.Item>
        <Form.Item style={{ marginBottom: '0' }}>
          <Flex justify="center" gap={ 10 }>
            <Button shape="round" icon={ <MobileOutlined /> } size="middle" onClick={ () => changeLoginType('phone') }>免密码登录</Button>
            <Button
              shape="circle"
              icon={ <WechatOutlined /> }
              size="middle"
              style={{ backgroundColor: '#3cb034', color: '#fff' }}
              onClick={ handleChangeWxLogin }
            ></Button>
          </Flex>
        </Form.Item>
      </Form>
    </>
  )
}

export default React.memo(PasswordLogin)

import React, { useState } from 'react'
import { Button } from 'antd'
import { getCode } from '@/api'

const Code = ({getKey}) => {
  const [isActive, setIsActive] = useState(false);
  const [src, setSrc] = useState("");

  const handleClick = async({}) => {
    setIsActive(true)
    try {
      const res = await getCode()
      setSrc(res.data.image)
      getKey(res.data.key)
    } catch (error) {
    }
  };

  return (
    <>
      {isActive ? (
        <img onClick={handleClick} src={src} style={{width:'102px',height:'32px',objectFit:'contain'}}/>
      ) : (
        <Button onClick={handleClick}>获取验证码</Button>
      )}
    </>
  );
};

export default Code;

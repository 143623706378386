import * as types from '../../mutation-types'
// 初始用户状态
const initialState = {
  searchName: '',
};



// 用户reducer函数
const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SEARCH:
      // 处理登录action
      return {
        ...state,
        searchName: action.name,
      };
    case types.LOGIN:
      // 处理登录action
      return {
        ...state,
        username: action.payload.username,
      };

    default:
      // 对于未知的action类型，返回当前的state
      return state;
  }
};

export default commonReducer;
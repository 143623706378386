import './index.scss'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setLoginShow, setMyFollows } from '@/redux/modules/user/action'
import { Button } from 'antd'
import Icon, { CodepenOutlined, EyeOutlined, LikeOutlined } from '@ant-design/icons'
import UserAvatar from '@/components/UserAvatar'
import ImageBox from '@/components/ImageBox'
import { toggleFollow, getMyFollows } from '@/api'
import { formatNumber } from '@/utils/utils'

const UserPopup = ({ data }) => {

  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  // 点击关注、取消关注
  const handleToggleFollow = async (e) => {
    e.stopPropagation()
    if (!user || !user.userInfo) {
      dispatch(setLoginShow(true))
      return
    }
    try {
      const res = await toggleFollow({
        userId: data.id,
        nickname: data.nickname
      })
      if (res.code === 0) {
        fetchUserInfos()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchUserInfos = async () => {
    try {
      const followsInfo = await getMyFollows()
      if (followsInfo.code === 0) {
        dispatch(setMyFollows(followsInfo.data || []))
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="user-popup">
      <div className="row-1">
        <div className="avatar-box">
          <UserAvatar shape="square" size={ 100 } avatar={ data.avatar } onClick={ () => window.open(`/user/overview/${ data.id }`) } />
        </div>
        <div className="info">
          <div className="name" onClick={ () => window.open(`/user/overview/${ data.id }`) }>{ data.nickname || '匿名' }</div>
          <div className="desc">{ data.introduction || '' }</div>
          <div className="follow-btn">
            {
              !user || (user.userInfo && user.userInfo.id) != data.id
                ? <>
                    {
                      user.userInfo && user.follows && user.follows.findIndex(item => `${item.followId}` === `${data.id}`) !== -1
                        ? <Button type="primary" size="small" onClick={ handleToggleFollow }>已关注</Button>
                        : <Button type="primary" size="small" onClick={ handleToggleFollow }>关注</Button>
                    }
                  </>
                : null
            }
            <span style={{ marginLeft: '20px' }}>粉丝：{ data.fansNum || 0 } </span>
          </div>
        </div>
      </div>
      <div className="row-2">
        {
          data.models && data.models.map(item => {
            return (
              <div className="model-cover-item" onClick={ () => item.id && window.open(`/detail/${ item.id }`) }>
                <ImageBox src={ item.modelImgUrl } />
              </div>
            )
          })
        }
      </div>
      <div className="row-3">
        <div className="show-num">
          <Icon
            component={ () => <svg t="1716717502241" class="icon" viewBox="0 0 1024 1024" version="1.1" width="16" height="16" fill="#1f1f1f"><path d="M511.83 65.47L109.85 260.6v502.5l401.98 195.14L913.8 763.1V260.6L511.83 65.47z m189.67 693.2L628 792.44l-0.46-290.55 70.72-32.5c78.72-36.18 127.56-12.66 127.72 85.5 0.15 97.77-48.54 168.87-124.48 203.78z m110.97-477.59L511.83 427.02 211.18 281.08l300.65-145.95 300.64 145.95z m-643.48 49.19l342.84 166.41v391.88L168.99 722.15V330.27z m0 0" p-id="23965"></path><path d="M695.19 517.16l-16.02 7.37 0.31 197.5 16.02-7.37c48.07-22.09 77.98-64.92 77.87-135.59-0.11-71.07-30.11-84.01-78.18-61.91zM377.22 621.55v-1.92c28.21-0.19 44.28-15.76 44.28-46.42 0-48.68-34.64-89.86-85.71-111.03-31.06-12.87-56.42-9.97-79.27 2.03l26.78 45.59c15.71-8.82 31.06-12.04 49.99-4.58 22.5 9.7 35.36 28.06 35.36 50.29 0 25.3-15.72 36.8-64.28 16.67v40.64c57.5 23.82 72.85 47.44 72.85 75.41 0 25.3-18.21 31.94-44.99 20.83-23.93-9.92-43.21-30.55-58.92-53.55l-24.64 25.44c18.57 30.31 46.79 58.87 88.56 76.18 50.35 20.86 92.85 8.19 92.85-43.94-0.01-37.16-22.87-70.39-52.86-91.64z m0 0" p-id="23966"></path></svg> }
          /> { formatNumber(data.modelNum || 0) } 模型
        </div>
        <div className="show-num">
          <EyeOutlined /> { formatNumber(data.viewNum || 0) } 查看
        </div>
        <div className="show-num">
          <LikeOutlined /> { formatNumber(data.praiseNum || 0) }点赞
        </div>
      </div>
    </div>
  )
}

export default React.memo(UserPopup)
